<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <b-alert
      v-for="alert in alerts"
             :key="alert.alertId"
             @dismissed="alertDismissed(alert)"
             fade
             class="text-center mt-3"
             style="font-size: 1.2em"
             variant="danger"
             :dismissible="alert.dismissible"
             :show="true"
      dismiss-label="Dismiss Alert"
    >
      <button
        class="btn abtn m-0 p-0"
              @click="alert.message ? openAlert(alert) : null"
              :title="alert.message ? 'Click to view more information' : ''"
        :style="getAlertCss(alert)"
      >
        <icon :icon="'exclamation-triangle'" class="mr-1"></icon>{{ alert.subject
        }}<icon :icon="'exclamation-triangle'" class="ml-1"></icon>
      </button>
    </b-alert>
    <div id="dashboard-nav" :class="'profile-form-container ' + (isBusy ? ' disabled-area' : '')">
      <div class="row">
        <div class="col-12 mb-4 text-center">
          <h1>{{ titleName }} Dashboard</h1>
          <p v-if="instructionText">{{ instructionText }}</p>
        </div>
      </div>
      <div class="row text-center">
        <div
          v-for="(route, index) in dashboardRoutes"
             :key="index"
             :id="'nav-' + route.name"
             :class="'col-lg-6 col-xl-4 my-3'"
             v-on="
            route.name == 'user-guide'
              ? { click: downloadUserGuide }
              : null
          ">
          <component :is="route.component ? 'router-link' : 'a'"
                     :href="route.component ? undefined : route.path"
                     exact
                     :to="route.path"
                     :target="route.meta.target">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color:' + route.meta.color">
                <icon :icon="route.meta.icon" :size="'lg'"></icon>
              </div>
              <div v-if="!route.meta.displayAbbr" class="col-9">
                {{ route.meta.display }}
              </div>
              <div v-if="route.meta.displayAbbr" class="col-9" style="padding: 15px 0">
                <div>{{ route.meta.displayAbbr }}</div>
                <div style="font-size: 48%">({{ route.meta.display }})</div>
              </div>
            </div>
          </component>
        </div>
        <div :class="'col-lg-6 col-xl-4 my-3'" v-if="!hasRoleActive(['100','200', '300', '350', '375', '400'])">
          <button class="w-100" @click="showUserGuide()">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color: #666'">
                <icon :icon="'info-circle'" :size="'lg'"></icon>
              </div>
              <div class="col-9">User Guide</div>
            </div>
          </button>
        </div>
        <div :class="'col-lg-6 col-xl-4 my-3'" v-if="hasRoleActive(['100'])">
          <a href="https://tnpal.atlassian.net/wiki/spaces/ecprofessional/pages/8978433/EC+Professional+TNPAL+Tutorials" target="_blank">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color: #666'">
                <icon :icon="'info-circle'" :size="'lg'"></icon>
              </div>
              <div class="col-9">User Guide</div>
            </div>
          </a>
        </div>
        <div :class="'col-lg-6 col-xl-4 my-3'" v-if="hasRoleActive(['200'])">
          <a href="https://tnpal.atlassian.net/wiki/spaces/ecprofessional/pages/50003980/Director+TNPAL+Tutorials" target="_blank">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color: #666'">
                <icon :icon="'info-circle'" :size="'lg'"></icon>
              </div>
              <div class="col-9">Director Guide</div>
            </div>
          </a>
        </div>
        <div :class="'col-lg-6 col-xl-4 my-3'" v-if="hasRoleActive(['300'])">
          <a href="https://tnpal.atlassian.net/wiki/spaces/coach/overview" target="_blank">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color: #666'">
                <icon :icon="'info-circle'" :size="'lg'"></icon>
              </div>
              <div class="col-9">Coach Guide</div>
            </div>
          </a>
        </div>
        <div :class="'col-lg-6 col-xl-4 my-3'" v-if="hasRoleActive(['350'])">
          <a href="https://tnpal.atlassian.net/wiki/spaces/licensingconsultant/overview" target="_blank">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color: #666'">
                <icon :icon="'info-circle'" :size="'lg'"></icon>
              </div>
              <div class="col-9">Licensing Consultant Guide</div>
            </div>
          </a>
        </div>
        <div :class="'col-lg-6 col-xl-4 my-3'" v-if="hasRoleActive(['375'])">
          <a href="https://tnpal.atlassian.net/wiki/spaces/licensingsupervisor/overview" target="_blank">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color: #666'">
                <icon :icon="'info-circle'" :size="'lg'"></icon>
              </div>
              <div class="col-9">Licensing Supervisor Guide</div>
            </div>
          </a>
        </div>
        <div :class="'col-lg-6 col-xl-4 my-3'" v-if="hasRoleActive(['400'])">
          <a href="https://tnpal.atlassian.net/wiki/spaces/manager/overview" target="_blank">
            <div class="row align-items-center mx-1 dash-main-btn">
              <div class="col-3" :style="'background-color: #666'">
                <icon :icon="'info-circle'" :size="'lg'"></icon>
              </div>
              <div class="col-9">Manager Guide</div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible"
           @close="closeModal(activeAlert)"
           :isModalVisible="this.isModalVisible"
           :skinName="'alert'">
      <div slot="header" class="w-100">
        <h2>
          <icon :icon="'exclamation-triangle'" class="mr-1"></icon>{{
 activeAlert.subject
          }}<icon :icon="'exclamation-triangle'" class="ml-1"></icon>
        </h2>
      </div>
      <div slot="body">
        <p v-html="activeAlert.message"></p>
      </div>
    </modal>
    <modal v-show="isUserGuideModalVisible"
           @close="closeUserGuide"
           :isModalVisible="isUserGuideModalVisible"
           :mustViewAll="false"
           :modalBusy="isBusy"
           :skinName="'doc-viewer'">
      <h2 slot="header">TNPAL User Guide</h2>
      <div class="text-left row" slot="body">
        <div v-if="isUserGuideModalVisible" class="col">
          <docViewer :downloadButtonText="'Download User Guide'" :fileCode="getUserGuideCode"></docViewer>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { getText, locale } from '@scripts/localized';
import { mapGetters } from 'vuex';
import docViewer from '@components/utility/doc-viewer';
export default {
  name: 'DashboardMain',
  data() {
    return {
      isModalVisible: false,
      isUserGuideModalVisible: false,
      activeAlert: {},
      alerts: [],
      isBusy: false,
      locale: locale
    };
  },
  components: {
    docViewer
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    },
    dashboardSideRoutes: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.loadAlerts();
  },
  computed: {
    ...mapGetters(['activeRole', 'userOrganizationRoles']),
    dashboardRoutes() {
      if (this.dashboardSideRoutes && this.dashboardSideRoutes.length > 1) {
        const links = [...this.dashboardSideRoutes];
        return links.splice(1, links.length);
      }
      return [];
    },
    titleName() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
        switch (this.activeRole.role.roleName) {
          case '100':
            return 'My ';
          case '200':
            return "Director's ";
          case '250':
            return "QEA's ";
          case '300':
            return "Coach's ";
          case '350':
            return `${getText('threeFiftyRole')}`;
          case '375':
            return "DHS Supervisor's";
          case '400':
            return `${getText('textID')} Manager`;
          case '500':
            return `${getText('textID')} Administrator`;

          case 'AdminUser':
            return 'Administrator ';
        }
      }
      return '';
    },
    instructionText() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
        switch (this.activeRole.role.roleName) {
          case '200':
            return `Use the buttons below to approve staff requests, view staff records, employment history, and run reports. You also can access email messages within ${getText(
              'textID'
            )}.`;
          case '250':
            return 'Use the buttons below to navigate the system.';
          case '300':
            return 'Use the buttons below to navigate the system.';
          case '350':
            return 'Use the buttons below to navigate the system.';
          case '375':
            return 'Use the buttons below to navigate the system.';
        }
      }
      return '';
    },
    getUserGuideCode() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName && this.locale && this.locale == 'tn') {
        switch (this.activeRole.role.roleName) {
          case '100':
            return 'TNPALUserGuide-ECEprofessional';
          case '200':
            return 'TNPALUserGuide-Director';
          case '300':
            return 'TNPALUserGuide-Coach';
          case '350':
            return 'TNPALUserGuide-LicensingConsultant';
          case '375':
            return 'TNPALUserGuide-LCSupervisor';
          case '400':
            return 'TNPALUserGuide-Manager-Admin';
          case '500':
            return 'TNPALUserGuide-Manager-Admin';
        }
      }
      return '';
    }
  },
  methods: {
    showUserGuide() {
      this.isUserGuideModalVisible = true;
    },
    closeUserGuide() {
      this.isUserGuideModalVisible = false;
    },
    openAlert(alert) {
      this.activeAlert = alert;
      this.isModalVisible = true;
    },
    closeModal(alert) {
      this.isModalVisible = false;
      this.activeAlert = {};
    },
    alertDismissed(alert) {
      if (alert.alertId) {
        const body = { alertId: alert.alertId };
        this.isBusy = true;
        this.$store
          .dispatch('dismissAlertRequest', body)
          .then((response) => {
            this.isBusy = false;
            this.alerts.filter((al) => al.alertId != alert.alertId);
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
    loadAlerts() {
      this.isBusy = true;
      this.$store
        .dispatch('getAlertsRequest')
        .then((response) => {
          this.isBusy = false;
          this.alerts = response;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    getAlertCss(alert) {
      return alert.message
        ? 'cursor: pointer;color:darkred;font-size:1.2em;'
        : 'cursor: default;color:darkred;font-size:1.2em;';
    },
    hasRoleActive(roleNames) {
      if (roleNames.length > 0) {
        if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
          return roleNames.some((role) => role == this.activeRole.role.roleName);
        }
        //no active org role can still access 100 level
        else if (roleNames.some((role) => role == '100')) return true;
        else return false;
      } else {
        return false;
      }
    },
    hasMultipleRoles() {
      if (this.userOrganizationRoles && this.userOrganizationRoles.length > 1) {
        return true;
      }
      else {
        return false;
      }
    },
    async downloadUserGuide() {
      this.isBusy = true;
      const fileCode = this.getUserGuideCode;
      await this.$downloadStaticFile(fileCode);
      this.isBusy = false;
    }
  }
};
</script>
