<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">My {{ $t('message.agencyPlural') }}</h1>
        </div>
        <!-- Main grid for page (Organization) -->
        <div>
          <div>
            <grid
              :entityTypeId="orgEntityTypeId"
              :formId="formId"
              :entityTypeName="orgEntityTypeName"
              :primaryEntity="primaryentity"
              :altGridId="altGridId"
              :key="gridKey"
              :showFormOnEmpty="false"
              :showAddButton="false"
              :showView="false"
              :showEdit="false"
              :showDisable="false"
              ref="organizationGrid"
              :additionalActions="additionalActions"
              :additionalActionsOnColumn="true"
              :checkForAdditionalActions="shouldShowAdditionalActions"
              :basePageSize="gridPageSize"
              :baseCurrentPage="gridCurrentPage"
              @setPageSize="setPageSize"
              @setCurrentPage="setCurrentPage"
            >
            </grid>
          </div>
        </div>
        <!-- Dependent grid (User)  -->
        <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
          <h2 slot="header">
            <span v-if="currentOrganizationName"
              >Users in {{ $t('message.agency') }} - {{ currentOrganizationName }}</span
            >
          </h2>
          <div class="text-left row" slot="body">
            <div v-if="filterOrganizationId" class="w-100 m-3">
              <organizationUsers
                :key="filterOrganizationId"
                :organizationId="filterOrganizationId"
                :organizationName="currentOrganizationName"
                :formId="userFormId"
                :entityTypeName="entityTypeName"
              >
              </organizationUsers>
            </div>
          </div>
        </modal>
        `
        <modal
          v-show="isSummaryModalVisible"
          @close="closeSummary"
          :isModalVisible="isSummaryModalVisible"
          :mustViewAll="false"
          :modalBusy="isBusy"
        >
          <h2 slot="header" class="text-center" style="width: 100%" v-if="isSummaryModalVisible">
            QRIS REPORT PERIOD<br />
            {{ getQrisStartDate() }}
            -
            {{ getQrisEndDate() }}
          </h2>
          <div slot="body" v-if="isSummaryModalVisible">
            <grid
              :entityTypeId="summaryEntityType"
              :formId="8"
              :entityTypeName="summaryEntityTypeName"
              :showFormOnEmpty="false"
              :showAddButton="false"
              :allowDownload="false"
              :key="filterOrganizationId + '0' + summaryKeyAdd"
              :primaryEntity="organizationEntity"
              :showDisable="false"
              :showEdit="false"
              :showView="false"
              ref="summaryGrid"
              :additionalActions="summaryAdditionalActions"
              :additionalActionsOnColumn="true"
              :basePageSize="summaryGridPageSize"
              :baseCurrentPage="summaryGridCurrentPage"
              @setPageSize="setSummaryPageSize"
              @setCurrentPage="setSummaryCurrentPage"
            ></grid>
            <b-button
              variant="wfr"
              @click="getAgencyReport"
              class="btn btn-wfr mr-4 float-right"
              title="Download Agency Summary PDF"
              id="pdf"
              style="position: relative; bottom: 45px"
            >
              <icon icon="file-pdf" size="lg"></icon>
            </b-button>
          </div>
        </modal>
        <modal
          v-show="isTrainingReportModalVisible"
          @close="closeTrainingReportModal"
          :isModalVisible="isTrainingReportModalVisible"
          :modalBusy="!fileUrl"
          :skinName="'doc-viewer'"
        >
          <h2 slot="header">Training Report</h2>
          <div class="text-left row" slot="body">
            <div class="col">
              <docViewer
                v-if="fileUrl"
                :downloadButtonText="'Download Training Report'"
                :fileUrl="fileUrl"
                :isStatic="false"
              ></docViewer>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { locale } from '@scripts/localized';
import organizationUsers from './organization-users.vue';
import docViewer from '@components/utility/doc-viewer';

  export default {
    name: 'Organizations',
    components: {
      organizationUsers,
      docViewer
    },
    computed: { 
      ...mapGetters(['userId', 'isAuthenticated']),
    },
    props: {
      primaryentity: { 
        type: Object,
        default: () => { }
      }
    },
    watch: {
      filterOrganizationId: function (newVal) {
        if (newVal) this.organizationEntity = { Id: newVal, Name: this.currentOrganizationName, EntityTypeId: this.orgEntityTypeId };
        else this.organizationEntity = {};
      }
    },
    data() { 
      return {
        isBusy: false,
        locale: locale,
        filterOrganizationId: '',
        currentOrganizationName: '',
        orgEntityTypeId: 2,
        orgEntityTypeName: 'Organization',
        summaryEntityType: 33,
        summaryEntityTypeName: 'Compliance Report',
        organizationEntity: {},
        formId: 13, //form required for grid.vue but not used here
        altGridId: 1,
        userEntityType: 1,
        userFormId: 1,
        entityTypeName: 'User',
        gridKey: 'open',
        additionalActions: [
          {
            title: 'View Facility',
            icon: 'eye',
            click: this.organizationSelected,
            check: this.shouldShowViewAction
          },
          // {
          //   title: 'Agency Summary',
          //   icon: 'table',
          //   click: this.showSummary,
          //   check: this.shouldShowSummaryReport
          // },
        ],
        summaryAdditionalActions: [
                  {
            title: 'Training Report',
            icon: 'book',
            click: this.getReport,
            check: this.shouldShowReportLink
          }
        ],
        additionalColumns: [],
        isModalVisible: false,
        isUserRecordModalVisible: false,
        isDirty: false,
        isLoading: false,
        emptySetMessage: 'No results found',
        gridPageSize: 5,
        gridCurrentPage: 1,
        isSummaryModalVisible: false,
        qrisStartDate: null,
        qrisEndDate: null,
        summaryGridPageSize: 5,
        summaryGridCurrentPage: 1,
        summaryKeyAdd: '',
        isTrainingReportModalVisible: false,
        fileUrl: ''
      };
    },
    created() {
      if (this.$route && this.$route.meta) {
        if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      }
    },
    methods: {
      setPageSize(size) {
        this.gridPageSize = parseInt(size);
      },
      setCurrentPage(page) {
        this.gridCurrentPage = parseInt(page);
      },
      organizationSelected(organization) {
        if (organization && organization.organizationId) {
          this.currentOrganizationName = organization.organizationName
          this.gridKey = 'open' + organization.organizationId;
          this.additionalColumns = this.agencyAdditionalColumns;
          this.organizationEntity = organization;
          this.filterOrganizationId = organization.organizationId;
          this.showModal();
        } else {
          this.gridKey = 'closed';
          this.additionalColumns = [];
          this.organizationEntity = {};
          this.filterOrganizationId = '';
        }
      },
      shouldShowAdditionalActions(item) {
        return true;
      },
      shouldShowViewAction(organization) {
        if (organization && organization.staffCount > 0)
          return true;
        return false;
      },
      showModal() {
        this.isModalVisible = true;
      },
      async closeModal() {
        const userAccept = await this.dirtyCheck();
        if (userAccept) {
          this.isModalVisible = false;
          this.gridKey = 'closed';
          this.organizationEntity = {};
          this.filterOrganizationId = '';
        }
      },
      async dirtyCheck() {
        return await new Promise((resolve, reject) => {
          if (this.isDirty && this.isAuthenticated) {
            const options = {
              title: 'Unsaved Data Warning',
              cancelLabel: 'Cancel'
            };
            this.$dialogs
              .confirm('Are you sure you want to close without saving?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  this.$emit('dirty', false);
                  this.isDirty = false;
                  resolve(true);
                } else {
                  resolve(false);
                }
              })
              .catch((error) => reject(error));
          } else resolve(true);
        });
      },
      formDirty(eventVal) {
        this.isDirty = eventVal;
        this.$emit('dirty', eventVal);
      },
      shouldShowReportLink(item) {
        return locale === 'tn' && !item.agencyIsClosed && Object.keys(this.organizationEntity).length > 0;
      }, 
      shouldShowSummaryReport(item) {
        return locale === 'tn' && item.staffCount > 0;
      }, 
      closeTrainingReportModal() {
        this.isTrainingReportModalVisible = false;
        this.fileUrl = '';
      },
      async getReport(item) {
        this.isTrainingReportModalVisible = true;
        const body = { username: item.userName, organizationId: this.filterOrganizationId };
        this.$store
          .dispatch('complianceReportRequest', body)
          .then((response) => {
            if (response && response.file) {
              this.fileUrl = response.file;
            }
          })
          .catch((errors) => {
            this.errors = errors;
          });  
      },
      closeSummary() {
        this.isSummaryModalVisible = false;
        this.organizationEntity = {};
        this.filterOrganizationId = '';
        this.summaryGridCurrentPage = 1;
        this.summaryGridPageSize = 5;
      },
      getQrisStartDate() {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return this.qrisStartDate.toLocaleDateString('en-US', options);
      },
      getQrisEndDate() {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return this.qrisEndDate.toLocaleDateString('en-US', options);
      },
      setSummaryPageSize(size) {
        this.summaryGridPageSize = parseInt(size);
      },
      setSummaryCurrentPage(page) {
        this.summaryGridCurrentPage = parseInt(page);
      },
      async showSummary(org) {
        if (!org || !org.organizationId) return;

      this.currentOrganizationName = org.organizationName;
      this.organizationEntity = org;
      this.filterOrganizationId = org.organizationId;
      this.isBusy = true;
      this.$store
        .dispatch('qrisCycleRequest')
        .then(async (response) => {
          this.qrisStartDate = new Date(response.startDate);
          this.qrisEndDate = new Date(new Date(response.endDate).getTime() - 86400);
          this.isBusy = false;
          this.isSummaryModalVisible = true;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async getAgencyReport() {
      this.isBusy = true;
      const body = { organizationId: this.filterOrganizationId };
      this.$store
        .dispatch('agencyReportRequest', body)
        .then((response) => {
          this.isBusy = false;
          if (response && response.report) {
            const orgName = this.strip(this.currentOrganizationName);
            let date = new Date().toLocaleString();
            date = date.replace(/\//g, '-');
            date = date.replace(/:/g, '-');
            date = date.replace(/,/g, '_');
            date = date.replace(/ /g, '');
            const fileName = `TNPAL_${orgName}_AgencySummaryReport_${date}.pdf`;
            this.savePdf(fileName, response.report);
            const options = { title: 'Download successful.' };
            this.$dialogs.alert(`Check your Downloads folder for a PDF of the report.`, options);
          }
        })
        .catch((errors) => {
          this.isBusy = false;
          this.errors = errors;
        });
    },
    strip(arr) {
      const arr2 = arr.split(' ');
      for (let item of arr2) {
        const index = arr2.indexOf(item);
        const ewc = item.endsWith(',');
        const ewp = item.endsWith('.');
        const e = item.length - 1;
        if (ewc || ewp) {
          item = item.slice(0, e);
          arr2[index] = item;
        }
        if (item.toLowerCase().includes('llc') || item.toLowerCase().includes('inc')) {
          arr2.splice(index, 1);
        }
      }
      return arr2.join(' ');
    },
    savePdf(strFileName, strData) {
      const pdfData = 'data:application/pdf;base64,' + escape(strData);

      const link = document.createElement('a');
      link.href = pdfData;
      link.download = strFileName;
      link.click();
    }
  }
};
</script>
