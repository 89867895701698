<template>
  <div>
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="'dash-menu-wrapper' + (isBusy ? ' disabled-area' : '')">
      <div id="scrollbarWrapper" class="scrollmenu menu-links" v-if="!isFirstTime">
        <nav id="scrollBar">
          <button
            id="dashLeftArrow"
            @click="scroll('left')"
            class="d-block d-md-none"
            aria-label="Scroll dashboard menu left"
          >
            <icon :icon="'arrow-left'" :size="'lg'"></icon>
          </button>
          <ul class="m-0 p-0">
            <li v-for="(route, index) in dashboardSideRoutes"
                :key="index"
                :id="'nav-' + route.name"
                :class="index == 0 ? 'mb-md-2' : 'my-md-2'"
                v-on="route.name == 'user-guide' ? { click: downloadUserGuide } : null">
              <component :is="route.component ? 'router-link' : 'a'"
                         :href="route.component ? undefined : route.path"
                         :to="route.path"
                         :target="route.meta.target"
                         :active-class="route.path == '/dashboard/pdp' || route.path == '/dashboard/profile' ? 'active' : ''"
                         :exact-active-class="
                  !(route.path == '/dashboard/pdp' || route.path == '/dashboard/profile') ? 'active' : ''
                "
              >
                <icon :icon="route.meta.icon" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span :class="(route.meta.displayAbbr ? 'd-none d-md-inline' : 'd-md-inline') + ' ml-md-1'">
                  {{ route.meta.display }}
                </span>
                <span v-if="route.meta.displayAbbr" class="d-md-none">{{ route.meta.displayAbbr }}</span>
              </component>
            </li>
            <li v-if="!hasRoleActive(['100','200', '300', '350', '375', '400'])" class="my-md-2">
              <button @click="showUserGuide()">
                <icon :icon="'info-circle'" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span class="d-md-inline ml-md-2">User Guide</span>
              </button>
            </li>
            <li v-if="hasRoleActive(['100'])" class="my-md-2">
              <a href="https://tnpal.atlassian.net/wiki/spaces/ecprofessional/pages/8978433/EC+Professional+TNPAL+Tutorials" target="_blank">
                <icon :icon="'info-circle'" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span class="d-md-inline ml-md-2">User Guide</span>
              </a>
            </li>
            <li v-if="hasRoleActive(['200'])" class="my-md-2">
              <a href="https://tnpal.atlassian.net/wiki/spaces/ecprofessional/pages/50003980/Director+TNPAL+Tutorials" target="_blank">
                <icon :icon="'info-circle'" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span class="d-md-inline ml-md-2">Director Guide</span>
              </a>
            </li>
            <li v-if="hasRoleActive(['300'])" class="my-md-2">
              <a href="https://tnpal.atlassian.net/wiki/spaces/coach/overview" target="_blank">
                <icon :icon="'info-circle'" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span class="d-md-inline ml-md-2">Coach Guide</span>
              </a>
            </li>
            <li v-if="hasRoleActive(['350'])" class="my-md-2">
              <a href="https://tnpal.atlassian.net/wiki/spaces/licensingconsultant/overview" target="_blank">
                <icon :icon="'info-circle'" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span class="d-md-inline ml-md-2">Licensing Consultant Guide</span>
              </a>
            </li>
            <li v-if="hasRoleActive(['375'])" class="my-md-2">
              <a href="https://tnpal.atlassian.net/wiki/spaces/licensingsupervisor/overview" target="_blank">
                <icon :icon="'info-circle'" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span class="d-md-inline ml-md-2">Licensing Supervisor Guide</span>
              </a>
            </li>
            <li v-if="hasRoleActive(['400'])" class="my-md-2">
              <a href="https://tnpal.atlassian.net/wiki/spaces/manager/overview" target="_blank">
                <icon :icon="'info-circle'" :size="'lg'" style="width: 21px" class="ml-1 ml-md-0"></icon>
                <br class="d-block d-md-none" />
                <span class="d-md-inline ml-md-2">Manager Guide</span>
              </a>
            </li>
          </ul>

          <button
            id="dashRightArrow"
            @click="scroll('right')"
            class="d-block d-md-none"
            aria-label="Scroll dashboard menu right"
          >
            <icon :icon="'arrow-right'" :size="'lg'"></icon>
          </button>
        </nav>
      </div>
      <!-- Page Content  -->
      <router-view
        v-bind:primaryentity="this.primaryentity"
        :dashboardSideRoutes="dashboardSideRoutes"
        @busy="dashboardBusy($event)"
      ></router-view>
    </div>
    <modal v-show="isUserGuideModalVisible"
           @close="closeUserGuide"
           :isModalVisible="isUserGuideModalVisible"
           :mustViewAll="false"
           :modalBusy="isBusy"
           :skinName="'doc-viewer'">
      <h2 slot="header">TNPAL User Guide</h2>
      <div class="text-left row" slot="body">
        <div v-if="isUserGuideModalVisible" class="col">
          <docViewer :downloadButtonText="'Download User Guide'" :fileCode="getUserGuideCode"></docViewer>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { locale } from '@scripts/localized';
import { mapGetters } from 'vuex';
import { routes } from '../router/routes';
import QuickSight from './dashboard-items/quicksight';
import docViewer from '@components/utility/doc-viewer';

export default {
  name: 'Dashboard',
  props: {
    userId: {
      type: String
    }
  },
  data() {
    return {
      isBusy: false,
      entity: [],
      userentitytypeid: 1,
      primaryentity: {},
      icon: 'user',
      isDirty: false,
      isUserGuideModalVisible: false,
      locale: locale,
      routes
    };
  },
  components: {
    docViewer
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'isFirstTime', 'activeRole', 'quickSightEnabled']),
    dashboardSideRoutes() {
      if (this.routes) {
        const dashboard = this.routes.filter(function (route) {
          return route.name === 'dashboard';
        });
        if (dashboard && dashboard.length > 0 && dashboard[0] && dashboard[0].children) {
          const links = [...dashboard[0].children];
          if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
            for (let i = 0; i < links.length; i++) {
              if (links[i].name == 'admin-requests') {
                if (this.activeRole.role.roleName == '400') {
                  links[i].meta.display = 'Manager Requests';
                } else if (this.activeRole.role.roleName == '500') {
                  links[i].meta.display = 'Admin Requests';
                }
              }
            }
            if (this.activeRole.role.roleName == 'AdminUser') {
              links.push({
                name: 'hangfire',
                path: '/hangfire',
                meta: {
                  display: 'Hangfire Dashboard',
                  color: '#3269cf',
                  icon: 'clock',
                  allowedRoles: ['AdminUser']
                }
              });
              if (this.quickSightEnabled) {
                links.push({
                  name: 'dashboard-quicksight',
                  path: '/dashboard/quicksight',
                  component: QuickSight,
                  props: true,
                  meta: {
                    display: 'Business Intelligence',
                    color: '#233873',
                    icon: 'layer-group',
                    allowedRoles: ['AdminUser', '500', '400']
                  }
                });
              }
            }
          }
          return links.filter(
            (link) =>
              (!link.meta.allowedRoles || this.hasRoleActive(link.meta.allowedRoles)) &&
              (!link.meta.requiresClaims || this.routeMatchesClaims(link))
          );
        }
      }
      return [];
    },
    getUserGuideCode() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName && this.locale && this.locale == 'tn') {
        switch (this.activeRole.role.roleName) {
          case '100':
            return 'TNPALUserGuide-ECEprofessional';
          case '200':
            return 'TNPALUserGuide-Director';
          case '300':
            return 'TNPALUserGuide-Coach';
          case '350':
            return 'TNPALUserGuide-LicensingConsultant';
          case '375':
            return 'TNPALUserGuide-LCSupervisor';
          case '400':
            return 'TNPALUserGuide-Manager-Admin';
          case '500':
            return 'TNPALUserGuide-Manager-Admin';
        }
      }
      return '';
    }
  },
  created() {
    if (this.isAuthenticated && !this.$store.getters['userId']) {
      this.isBusy = true;
      this.$store.dispatch('profileRequest').then((result) => {
        this.loadPrimaryEntity();
        this.isBusy = false;
      });
    } else {
      this.loadPrimaryEntity();
    }
  },
  methods: {
    showUserGuide() {
      this.isUserGuideModalVisible = true;
    },
    closeUserGuide() {
      this.isUserGuideModalVisible = false;
    },
    loadPrimaryEntity() {
      let user = '';
      if (this.userId) user = this.userId;
      else {
        const id = this.$store.getters['userId'];
        if (id) user = id;
      }
      if (user && this.userentitytypeid) {
        this.primaryentity = { Id: user, EntityTypeId: this.userentitytypeid };
        this.entity.push(this.primaryentity);
      }
    },
    scroll(direction) {
      const content = document.querySelector('.menu-links');
      if (direction == 'left') {
        content.scrollLeft -= 100;
      } else {
        content.scrollLeft += 100;
      }
      this.showHideButtons();
    },
    showHideButtons() {
      const content = document.querySelector('.menu-links');
      const leftArrow = document.getElementById('dashLeftArrow');
      const rightArrow = document.getElementById('dashRightArrow');
      const contentWidth = content.scrollWidth - content.clientWidth;

      if (content.scrollLeft == 0) {
        leftArrow.style.visibility = 'hidden';
        rightArrow.style.visibility = 'visible';
      } else if (content.scrollLeft == contentWidth) {
        leftArrow.style.visibility = 'visible';
        rightArrow.style.visibility = 'hidden';
      } else {
        leftArrow.style.visibility = 'visible';
        rightArrow.style.visibility = 'visible';
      }
    },
    routeMatchesClaims(route) {
      if (!route.meta.requiresClaims) return true;
      if (
        !(
          this.activeRole &&
          this.activeRole.role &&
          this.activeRole.role.claims &&
          this.activeRole.role.claims.length > 0
        )
      )
        return false;
      let match = false;
      route.meta.requiresClaims.forEach((c) => {
        if (this.activeRole.role.claims.some((claim) => claim == c)) match = true;
      });
      return match;
    },
    hasRoleActive(roleNames) {
      if (roleNames.length > 0) {
        if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
          return roleNames.some((role) => role == this.activeRole.role.roleName);
        }
        //no active org role can still access 100 level
        else if (roleNames.some((role) => role == '100')) return true;
        else return false;
      } else {
        return false;
      }
    },
    async downloadUserGuide() {
      this.isBusy = true;
      const fileCode = this.getUserGuideCode;
      await this.$downloadStaticFile(fileCode);
      this.isBusy = false;
    },
    dashboardBusy(eventVal) {
      this.isBusy = eventVal;
    }
  },
  mounted() {
    const leftArrow = document.getElementById('dashLeftArrow');
    const content = document.querySelector('.menu-links');
    if (leftArrow) {
      leftArrow.style.visibility = 'hidden';
      content.addEventListener('scroll', this.showHideButtons);
    }
  }
};
</script>
